import { PureComponent } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import dayjs from "dayjs";
import classnames from "classnames";
import { connect } from "react-redux";
import { Box, Button, Stack, Typography } from "@mui/material";

import * as EventDirectorActions from "../../../actions/eventDirector";

import { Heading } from "../../layout/page/Heading";
import { Segment } from "../../../styleguide/patterns/segment";
import { EventDate } from "../eventDate";
import { T } from "../../util/t";
import { isDivisionTeamType } from "../../eventDivisions/helpers";
import { JudgingConnection } from "./JudgingConnection";
import { EventLinkItem } from "./EventLinkItem";
import { NoSearchResults, SearchForm } from "../../search/search";
import { SearchRegExp, nonWhitespacePattern } from "../../../utils/utils";
import { DashboardMenu } from "components/layout/event/dashboard/DashboardMenu";
import { EditGroupDivision } from "components/layout/eventDivision/GroupDivisionsDialog";
import { makeStyles } from "components/providers/makeStyles";
import { Notification } from "../../layout/notifications/Notification";
import { AccordionItem } from "../../layout/accordions/AccordionItem";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { SimpleList } from "../../layout/lists/SimpleList";
import { Link } from "react-router-dom";
import { ViewPaymentsButton } from "components/payments/ViewPaymentsButton";
import { DetachableChannelProvider } from "../../providers/Realtime/RealtimeProvider";

@connect(({ events, eventDashboards }, { eventId }) => ({
    loaded: !!eventDashboards.getIn([eventId, "event_divisions"]),
    hasSchedule: !!events.getIn(["schedules", eventId, "breaks"]),
    eventDivisions: eventDashboards.getIn([eventId, "event_divisions"], Immutable.fromJS([
        { name: "Women" }, { name: "Masters" }, { name: "Juniors" }
    ]))
}))
export class EventDivisions extends PureComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        eventId: PropTypes.string.isRequired,
        loaded: PropTypes.bool.isRequired,
        hasSchedule: PropTypes.bool.isRequired,
        eventDivisions: PropTypes.instanceOf(Immutable.List)
    };

    state = { search: "" };

    componentDidMount = ({ dispatch, eventId } = this.props) => dispatch(EventDirectorActions.getEventDivisions(eventId));

    getTotalAthletesAndTeams = eventDivisions => eventDivisions.reduce((total, ed) =>
        isDivisionTeamType(ed)
            ? { ...total, team: (total.team || 0) + ed.get("athlete_count", 0) }
            : { ...total, athlete: total.athlete + ed.get("athlete_count", 0) }
    , { athlete: 0, team: null });

    onSearchChange = search => {
        this.setState({ search });
    };

    getFilteredEventDivisions = (eventDivisions) => {
        const words = this.state.search.match(nonWhitespacePattern);
        if (this.props.loaded && words) {
            return words.reduce((eventDivisions, word) => {
                const searchRegex = SearchRegExp(word);

                return eventDivisions.filter(eventDivision =>
                    eventDivision.get("name").match(searchRegex)
                      || !eventDivision.get("event_divisions").filter(groupEventDivision =>
                          groupEventDivision.get("name").match(searchRegex)).isEmpty()
                );
            }, eventDivisions);
        }

        return eventDivisions;
    };

    render = () => {
        const { eventId, eventDivisions, hasSchedule, loaded } = this.props,
            filteredEventDivisions = this.getFilteredEventDivisions(eventDivisions);

        return (
            <Segment className={classnames({ "skeleton": !loaded })}
                     title="Event divisions"
                     subtitle={<T count={this.getTotalAthletesAndTeams(eventDivisions)}>athlete_and_team_count</T>}
                     action={<DashboardMenu eventId={eventId} eventDivisions={eventDivisions} hasSchedule={hasSchedule} loaded={loaded}/>}>

                {eventDivisions?.size > 4 &&
                <SearchForm
                    className="search-component"
                    search={this.state.search}
                    placeholder="Enter division"
                    onChange={this.onSearchChange}
                />}

                {filteredEventDivisions.isEmpty()
                    ? <NoSearchResults
                        noResultsText={<T>Sorry, we can't seem to find that division 😞</T>}
                        clearResultsText={<T>Clear your search to view all divisions</T>}
                        onClear={() => this.setState({ search: "" })}
                    />
                    : <SimpleList>
                        {filteredEventDivisions.map(ed => ({
                            title: ed.get("name"),
                            description: ed.get("event_divisions", Immutable.List()).map(ed => ed.get("name")).join(", "),
                            to: ed.get("id") && (ed.get("status") === "drawn" ? `/events/${eventId}/divisions/${ed.get("id")}/draw` : `/events/${eventId}/division/${ed.get("id")}/entries`),
                            aside: <TitleRight hasChildren={ed.get("event_divisions", Immutable.List()).size > 0} eventDivisions={eventDivisions} eventId={eventId} ed={ed} />
                        }))}
                    </SimpleList>
                }
            </Segment>
        );
    };
}

const useTitleRightStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center"
    },
    athleteCount: {
        display: "flex",
        alignItems: "center"
    },
    divider: {
        marginLeft: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        borderLeft: `1px solid ${theme.palette.input.border}`
    }
}));

const TitleRight = ({ hasChildren, eventDivisions, eventId, ed }) => {
    const classes = useTitleRightStyles();

    const athleteCount = (
        <div className={classnames(classes.athleteCount, { [classes.divider]: hasChildren })}>
            {ed.get("athlete_count")}
            <ChevronRight/>
        </div>
    );

    if (!hasChildren) return athleteCount;

    return (
        <div className={classes.container}>
            <EditGroupDivision divisions={eventDivisions} eventId={eventId} division={ed} />
            {athleteCount}
        </div>
    );
};

@connect(({ events }, { eventId }) => {
    const schedule = events.getIn(["schedules", eventId], Immutable.Map()),
        blocks = schedule.get("breaks", Immutable.List()).map((b, i) => b.set("index", i)).sortBy(b => b.get("position"));

    let scheduleSize = schedule.get("podiums", Immutable.List()).valueSeq().map(p => p.size).max() || 0;
    // eslint-disable-next-line
    for (; scheduleSize > 0 && !schedule.get("podiums", Immutable.Map()).valueSeq().map(p => p.get(scheduleSize - 1)).some(s => s); scheduleSize--);

    return {
        loaded: !!events.getIn(["mini", parseInt(eventId)]) || !!events.getIn(["schedules", eventId]),
        event: events.getIn(["mini", parseInt(eventId)], Immutable.fromJS({
            date: new Date(),
            days_window: 2
        })),
        blocks: blocks.map((b, i) => {
            const nextBreakPosition = blocks.getIn([i + 1, "position"]),
                endIndex = Immutable.List([nextBreakPosition, scheduleSize]).filter(s => s).min() - 1;

            if (b.get("position") >= scheduleSize) return undefined;
            if (nextBreakPosition === b.get("position")) return undefined;

            const lastHeats = schedule.get("podiums").valueSeq()
                    .map(p => p.get(endIndex))
                    .filter(id => id)
                    .map(id => events.getIn(["heats", eventId, id])),
                endTime = dayjs(lastHeats.first().get("start_time") || lastHeats.first().get("scheduled_time")).add(
                    lastHeats.map(h => h.get("heat_duration_minutes")).max(), "minute").toJSON();
            return Immutable.fromJS({ start: b.get("date"), end: endTime, index: b.get("index") });
        }).filter(b => b)
    };
})
export class EventSchedule extends PureComponent {
    static propTypes = {
        eventId: PropTypes.string.isRequired,
        loaded: PropTypes.bool.isRequired,
        schedule: PropTypes.instanceOf(Immutable.Map)
    };

    render = () => {
        const { eventId, event, blocks, loaded } = this.props,
            hasSchedule =  blocks.size > 0,
            plural = hasSchedule ? blocks.size !== 1 : event.get("days_window") !== 1;

        return (
            <Segment className={`${!loaded ? "skeleton": ""}`}
                     title="Schedule"
                     subtitle={hasSchedule ?
                         <T size={blocks.size} plural={plural}>schedule_blocks</T> :
                         <T size={event.get("days_window")} plural={plural}>schedule_days</T>}>

                {hasSchedule ?
                    <Stack direction="row" spacing={1}>
                        {blocks.map(block => (
                            <DayBlock key={block.get("index")} block={block} eventId={eventId}/>
                        ))}
                    </Stack> :
                    <SimpleList>
                        {[{ title: <EventDate date={event.get("date")} window={event.get("days_window")}/> }]}
                    </SimpleList>
                }
            </Segment>
        );
    };
}

const useStyles = makeStyles(theme => ({
    column: {
        display: "flex",
        flexDirection: "column"
    },
    dayBlock: {
        border: `1px solid ${theme.palette.border.light}`,
        borderRadius: theme.spacing(.5),
        backgroundColor: theme.palette.background.paper,
    },
    day: {
        backgroundColor: theme.palette.background.mid,
        textAlign: "center"
    },
    spacing: {
        padding: theme.spacing(1, 2),
    },
    separator: {
        height: theme.spacing(2),
        width: 0,
        margin: theme.spacing(1, 0, 1, "50%"),
        borderLeft: `2px dotted ${theme.palette.input.hover}`
    }
}));
const DayBlock = ({ block, eventId }) => {
    const classes = useStyles();

    return (
        <Box component={Link} className={`${classes.dayBlock} ${classes.colummn}`} to={`/events/${eventId}/schedule/edit#block${block.get("index") + 1}`}>
            <Typography className={`${classes.day} ${classes.spacing}`} variant="subtitle2">{dayjs(block.get("start")).format("D MMM")}</Typography>
            <div className={`${classes.column} ${classes.spacing}`}>
                <Typography variant="subtitle2">{dayjs(block.get("start")).format("hh:mm a")}</Typography>
                <div className={classes.separator}/>
                <Typography variant="subtitle2">{dayjs(block.get("end")).format("hh:mm a")}</Typography>
            </div>
        </Box>
    );
};

@connect(({ events }, { match }) => ({ event: events.getIn(["mini", parseInt(match.params.id)], Immutable.Map()) }))
export class EventDashboard extends PureComponent {
    static propTypes = {
        event: PropTypes.instanceOf(Immutable.Map).isRequired
    };

    render = ({ event, currentUser, match: { params: { id } } } = this.props) =>
        <div className={`event-dashboard wrapper ${event.isEmpty() ? "skeleton" : ""}`}>
            <Heading title="Event dashboard" subtitle={<T>{event.get("name", "Event name")}</T>} actions={
                <>
                    { window.location.search.includes("entries") &&
                        <Button variant="contained" component={Link} to={"./entries"}><T>All entries</T></Button> }
                    { event.get("payment_options") && event.get("status") === "cancelled" && currentUser.getIn(["organisation", "stripe_account_type"]) === "standard" && <ViewPaymentsButton eventId={event.get("id")} variant="contained" /> }
                </>
            }/>
            <EventDivisions eventId={id} organisation={event?.get("organisation")}/>

            <EventSchedule eventId={id}/>

            {!event.isEmpty() && !Immutable.List(["upcoming", "scheduled", "finished", "results_published", "cancelled"]).contains(event.get("status")) &&
            <DetachableChannelProvider channelName={`event-presence:${id}`}><JudgingConnection eventId={id}/></DetachableChannelProvider>}

            <AccordionItem
                id="dashboard-links"
                expandIcon={<ExpandMore/>}
                summary={<Typography variant="subtitle2"><T>Links</T></Typography>}
            >
                {!event.isEmpty() &&
                    <>
                        {Immutable.List(["upcoming", "scheduled"]).contains(event.get("status")) &&
                            <EventLinkItem link={`/events/${id}/registration`} title="Athlete registration" share={true}/>}

                        {!Immutable.List(["upcoming", "scheduled"]).contains(event.get("status")) &&
                            <EventLinkItem link={`/events/${id}/spectate`} title="TV scoreboard"/>}

                        <EventLinkItem link={`/events/${id}`} title="Public event link" share={true}/>

                        <EventLinkItem link={`/events/${id}/embed`} title="Embed URL"/>

                        {event.get("event_divisions")?.some(ed => ed.getIn(["heat_config", "has_priority"])) &&
                            <EventLinkItem link={`/events/${id}/priority`} title="LED Priority Display"/>}

                        <EventLinkItem link={`/events/${id}/broadcast`} title="Broadcast graphics URL (Transparent)"/>

                        <EventLinkItem link={`/events/${id}/broadcast/chromakey`} title="Broadcast graphics URL (Chromakey)"/>

                        <Notification type="info" className="fit-content" text={<T link={`/events/${id}/reports`}>direction_to_reports_page</T>}/>
                    </>}
            </AccordionItem>
        </div>;
}
